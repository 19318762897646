import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  LinearProgress,
  Grow,
  CircularProgress,
  Skeleton,
  ButtonGroup,
  Grid,
  Card,
  Popper,
  Paper,
  ClickAwayListener,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  styled,
  Collapse,
  Fade,
} from "@mui/material";
import {
  ExpandMore,
  SpeedOutlined,
  InfoOutlined,
  Speed,
  SpeedRounded,
  ContentCopyOutlined,
  CheckCircleOutlineOutlined,
  Download,
  ThumbUpSharp,
  ThumbDown,
  ThumbUp,
  ThumbUpAltOutlined,
  SentimentNeutral,
  SentimentSatisfied,
  SentimentVerySatisfiedOutlined,
  SentimentSatisfiedAltOutlined,
  SentimentDissatisfiedOutlined,
  SentimentNeutralOutlined,
  DataArrayOutlined,
  BarChartOutlined,
  ForumOutlined,
  CommentOutlined,
  GroupWorkOutlined,
  GroupsOutlined,
  QuestionAnswerOutlined,
  GavelOutlined,
  HandshakeOutlined,
  TaskAltOutlined,
  HighlightOffOutlined,
  PublicOutlined,
  AnalyticsOutlined,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  SentimentVeryDissatisfiedOutlined,
  ExpandRounded,
  MoreVert,
  MoreVertOutlined,
  MoreHorizOutlined,
  Mood,
  PriorityHigh,
  HowToVoteOutlined,
  Stars,
  Close,
  DownloadOutlined,
  FilterList,
  Search,
  MenuBookOutlined,
  DescriptionOutlined,
  ArrowRight,
  ArrowCircleRight,
  ArrowCircleRightOutlined,
  PsychologyOutlined,
  ScienceOutlined,
  LightbulbCircleOutlined,
  LightbulbOutlined,
  SendOutlined,
  CheckCircle,
  UndoOutlined,
  RedoOutlined,
  DeleteOutline,
  VisibilityOutlined,
} from "@mui/icons-material";
import html2canvas from "html2canvas";
import { IconButton, Tooltip } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  ArrowDownOnSquareIcon,
  ArrowPathIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDuplicateIcon,
  EyeSlashIcon,
  EyeIcon,
  InformationCircleIcon,
  SparklesIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ArrowTopRightOnSquareIcon,
  HeartIcon,
  ChatBubbleLeftIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import HelperFunctions from "./HelperFunctions";
import MainSummary from "./MainSummary";
import { set } from "lodash";
import VibeScorer from "./VibeScorer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CommentComponent from "./CommentComponent";
import { formatPlatform, formatNumber } from "./Helpers";

// Update the parseMarkdown function at the top of the file
const parseMarkdown = (text) => {
  if (!text) return "";

  // First, normalize any multi-line bold text into single-line bold text
  text = text.replace(/\*\*([\s\S]*?)\*\*/g, (match, p1) => {
    // Remove newlines and normalize spaces within bold text
    return `**${p1.replace(/\s+/g, ' ').trim()}**`;
  });

  return (
    text
      // Username tags: @username
      .replace(
        /@(\w+)/g,
        '<span class="user-tag" data-username="$1" style="color: #4728c4; cursor: pointer; font-weight: 600;">@$1</span>'
      )
      // Topic tags: [[topic]]
      .replace(
        /\[\[(.*?)\]\]/g,
        '<span class="topic-tag" data-topic="$1">$1</span>'
      )
      // Bold: **text** - Now handles pre-normalized bold text
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      // Italic: *text* or _text_
      .replace(/[*_](.*?)[*_]/g, "<em>$1</em>")
      // Links: [text](url)
      .replace(
        /\[([^\]]+)\]\(([^)]+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
      )
      // Line breaks: \n
      .replace(/\n/g, "<br/>")
  );
};

const GlassMorphicPaperComponent = React.forwardRef(
  function GlassMorphicPaperComponent(props, ref) {
    return (
      <Paper
        ref={ref}
        elevation={0}
        sx={{
          borderRadius: 8,
          background: "rgba(255, 255, 255, 0.1)", // More transparent for better effect
          backdropFilter: "blur(12px)", // This creates the glass effect
          padding: "1em",
          margin: ".5em",
          border: "1px solid rgba(255, 255, 255, 0.3)", // Optional: Add a border for better visibility
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow for depth
          height: "fit-content",
          width: "100%",
        }}
      >
        {props.children}
      </Paper>
    );
  }
);

// Add this after the existing imports
const ScreenshotWrapper = styled("div")({
  position: "absolute",
  left: "-9999px",
  top: "-9999px",
  width: "fit-content",
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  padding: "16px",
});

const VibeCheckSection = React.memo(function VibeCheckSection(props) {
  const Functions = HelperFunctions();

  const {
    search,
    selectedPostData,
    exportVibeScoreSection,
    screenshot,
    selectedGroups,
    selectedTags,
    selectedAccounts,
    fullCommentData,
    searchedComments,
    selectedComments,
    isMobile,
    fullTable,
    searched,
    searchFiltered,
    selected,
    quickView,
    handleSetSearch,
    campaignFiltered,
    tagFiltered,
    accountFiltered,
    searching,
    singlePost,
    searchComments,
    listKey,
    goToPost, // Add this
    onCommentDrop, // Add this
  } = props;

  // Add the comment count calculations
  const totalCommentCount = React.useMemo(() => {
    return (
      selectedPostData?.reduce(
        (sum, post) => sum + (post.data?.totalComments || 0),
        0
      ) || 0
    );
  }, [selectedPostData]);

  const sampledCommentCount = React.useMemo(() => {
    return fullCommentData?.length || 0;
  }, [fullCommentData]);

  const searchedCommentCount = React.useMemo(() => {
    return searchedComments?.reduce((sum, comment) => sum + 1, 0) || 0;
  }, [searchedComments]);

  // First add these percentage calculations with the other useMemo calculations:
  const sampledPercentage = React.useMemo(() => {
    return totalCommentCount > 0
      ? ((sampledCommentCount / totalCommentCount) * 100).toFixed(1)
      : 0;
  }, [totalCommentCount, sampledCommentCount]);

  const searchedPercentage = React.useMemo(() => {
    return sampledCommentCount > 0
      ? ((searchedCommentCount / sampledCommentCount) * 100).toFixed(1)
      : 0;
  }, [sampledCommentCount, searchedCommentCount]);

  const [hidden, setHidden] = React.useState(false);
  const [vibeCheck, setVibeCheck] = React.useState(null);
  const [exporting, setExporting] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [openExplainer, setOpenExplainer] = React.useState(false);
  const vibeCheckScreenshotRef = React.useRef(null);
  const [expanded, setExpanded] = React.useState(false);
  const explainerButtonRef = React.useRef(null);
  const [showInsights, setShowInsights] = React.useState(true);
  const [openImage, setOpenImage] = React.useState(false); // State to manage image visibility
  const [vibeCheckText, setVibeCheckText] = React.useState("");
  const [insightsSummary, setInsightsSummary] = React.useState("");
  const [moodDescriptors, setMoodDescriptors] = React.useState([]);
  const [hasInsights, setHasInsights] = React.useState(false);
  const [insightTopics, setInsightTopics] = React.useState("");
  const [notableComments, setNotableComments] = React.useState([]);
  const [generatingInsights, setGeneratingInsights] = React.useState(false);
  const insightsRef = React.useRef(null);
  const [copiedInsights, setCopiedInsights] = React.useState(false);
  const [notableCommentsHistory, setNotableCommentsHistory] = React.useState([
    [],
  ]);
  const [historyIndex, setHistoryIndex] = React.useState(0);
  // Add this state for tracking hover state of each comment
  const [hoveredCommentId, setHoveredCommentId] = React.useState(null);
  // Add this state variable with the other useState declarations
  const [hideNotableComments, setHideNotableComments] = React.useState(false);
  // Add this to the existing state declarations at the top
  const [notablePosts, setNotablePosts] = useState([]);
  const [hideNotablePosts, setHideNotablePosts] = useState(false);

  // Add this array near the top of your component
  const thinkingVerbs = [
    "Reading",
    "Processing",
    "Analyzing",
    "Identifying themes",
    "Extracting comments",
    "Thinking",
    "Evaluating",
    "Summarizing",
    "Reviewing",
    "Finalizing",
  ];

  // Add this state
  const [currentVerb, setCurrentVerb] = useState(thinkingVerbs[0]);

  // Add an AbortController ref at the component level
  const abortControllerRef = React.useRef(null);

  // Add this effect
useEffect(() => {
  let intervalId;
  
  // Only run the effect when generatingInsights is true
  if (generatingInsights) {
    // Start with first verb
    setCurrentVerb(thinkingVerbs[0]);
    
    const nextVerb = () => {
      setCurrentVerb(prevVerb => {
        const currentIndex = thinkingVerbs.indexOf(prevVerb);
        return thinkingVerbs[(currentIndex + 1) % thinkingVerbs.length];
      });
      
      // Set new random interval for next change
      intervalId = setTimeout(nextVerb, Math.floor(Math.random() * 5000) + 2000);
    };
    
    // Start first interval
    intervalId = setTimeout(nextVerb, Math.floor(Math.random() * 5000) + 2000);
  }

  return () => {
    if (intervalId) {
      clearTimeout(intervalId);
    }
  }
}, [generatingInsights]); 

  React.useEffect(() => {
    if (
      selectedPostData &&
      selectedPostData.length > 0 &&
      vibeCheck &&
      vibeCheck.vibeScore
    ) {
      let newText =
        `Vibe Score: ${vibeCheck.vibeScore.toFixed(1)}\n\n` +
        `Sentiment Score: ${vibeCheck.sentimentData.sentimentScore.toFixed(
          1
        )}/10\n` +
        "Positive: " +
        vibeCheck.sentimentData.positivePercentage +
        "\n" +
        "Neutral: " +
        vibeCheck.sentimentData.neutralPercentage +
        "\n" +
        "Negative: " +
        vibeCheck.sentimentData.negativePercentage +
        "\n\n" +
        `Relevance Score: ${vibeCheck.relevanceData.relevanceScore.toFixed(
          1
        )}/10\n` +
        "On-Topic: " +
        vibeCheck.relevanceData.positivePercentage +
        "\n" +
        "General: " +
        vibeCheck.relevanceData.neutralPercentage +
        "\n" +
        "Off-Topic: " +
        vibeCheck.relevanceData.negativePercentage +
        "\n\n" +
        `Consensus Score: ${vibeCheck.consensusData.consensusScore.toFixed(
          1
        )}/10\n` +
        "Agreement: " +
        vibeCheck.consensusData.positivePercentage +
        "\n" +
        "Mixed: " +
        vibeCheck.consensusData.neutralPercentage +
        "\n" +
        "Arguing: " +
        vibeCheck.consensusData.negativePercentage +
        "\n\n" +
        // Add Insights Summary section if it exists
        (insightsSummary
          ? `Insights Summary:\n${insightsSummary.replace(
              /[\[\]**_]/g,
              ""
            )}\n\n`
          : "") +
        // Add Key Themes section if topics exist
        (insightTopics && insightTopics.length > 0
          ? `Key Themes:\n${insightTopics.join(", ")}\n\n`
          : "") +
        // Add Notable Comments section if they exist and aren't hidden
        (!hideNotableComments && notableComments && notableComments.length > 0
          ? `Notable Comments:\n${notableComments
              .map((comment) => `${comment.username}: ${comment.text}`)
              .join("\n\n")}\n\n`
          : "");

      // Remove any trailing newlines
      newText = newText.trim() + "\n";

      setVibeCheckText(newText);
    } else {
      setVibeCheckText("");
    }

    //remove lines with null
  }, [
    vibeCheck,
    insightsSummary,
    insightTopics,
    notableComments,
    hideNotableComments,
  ]);

  const resetInsightStates = () => {
    setInsightsSummary("");
    setMoodDescriptors([]);
    setInsightTopics("");  // Note: Changed to empty array since insightTopics appears to be an array
    setNotableComments([]);
    setNotableCommentsHistory([[]]);
    setNotablePosts([]);
    setHistoryIndex(0);
  };

  React.useEffect(() => {
    // Always reset insights while actively searching
    if (searching) {
      resetInsightStates();
      return;
    }
    if(generatingInsights){
      resetInsightStates();
      return;
    }
  }, [searching, generatingInsights]);


  // Modify the loadInsights function to accept an AbortSignal
  const loadInsights = React.useCallback(async (signal) => {
    try {
      setGeneratingInsights(true);
      resetInsightStates();
      
      // Add signal check at the start
      if (signal?.aborted) {
        console.log('Aborted before starting');
        return;
      }
      
      // Breakdown searched comments by postId into posts
      let commentsByPost = searchedComments.reduce((acc, comment) => {
        const existingPost = acc.find((post) => post.postId === comment.postId);
        if (existingPost) {
          existingPost.comments += `id: ${comment.comment.id} username: @${comment.comment.username} text: ${comment.comment.text}\n`;
        } else {
          acc.push({
            postId: comment.postId,
            comments: `id: ${comment.comment.id} username: @${comment.comment.username} text: ${comment.comment.text}\n`,
          });
        }
        return acc;
      }, []);

      let analytics = vibeCheckText;
      let totalCommentCount = selectedPostData.reduce(
        (sum, post) => sum + (post.data?.totalComments || 0),
        0
      );
      let sampledCommentCount = fullCommentData.length;
      let searchedCommentCount = searchedComments.reduce(
        (sum, comment) => sum + 1,
        0
      );

      let commentStatement =
        `Number of total comments in this grouping: ${totalCommentCount}\n` +
        `Number of sampled comments in this grouping: ${sampledCommentCount}\n` +
        `Number of comments that match the search query: ${searchedCommentCount}\n
      Do not mention sampling. For percentages, use the sampled number / searched number.
      `;

      let filterType =
        selectedPostData.length === 1
          ? `\n - a single post` 
          : campaignFiltered || tagFiltered || accountFiltered || searched || search
          ? `${
              campaignFiltered
                ? "\n - comments from campaign(s) called: " +
                  selectedGroups.map((group) => group.name).join(", ")
                : ""
            }` +
            `${
              searched && search.trim() !== ""
                ? `\n - a search for comments pertaining to: "${search}" `
                : ""
            }` +
            `${
              accountFiltered
                ? "\n - from the social media accounts: " +
                  selectedAccounts.map((account) => account).join(", ")
                : ""
            }` +
            `${
              tagFiltered
                ? "\n - from content with category tags: " +
                  selectedTags.map((tag) => tag).join(", ")
                : ""
            }`
          : "";

      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 60000)
      );

      // Add signal to the API call and handle abort more explicitly
      let data;
      try {
        data = await Promise.race([
          Functions.summarizeComments(
            commentsByPost,
            analytics + commentStatement,
            filterType,
            signal
          ),
          timeoutPromise,
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Request was aborted during API call');
          return;
        }
        throw error; // Re-throw other errors
      }

      // Add another signal check after the API call
      if (signal?.aborted) {
        console.log('Aborted after API call');
        return;
      }

      if (!data || !data.summary) {
        resetInsightStates();
        return;
      }

      // Add line breaks between sentences
      const formattedSummary = data.summary
        .replace(/\.\s+/g, ".\n\n")
        .replace(/\n$/, "");
      setInsightsSummary(formattedSummary);
      setInsightTopics(data.topics);
      setMoodDescriptors(data.moods);
      let notableComments = [];
      if (
        data &&
        data.notable_comments &&
        Array.isArray(data.notable_comments)
      ) {
        notableComments = searchedComments
          .filter(
            (comment) =>
              comment?.comment?.id && // Verify comment has required structure
              data.notable_comments.some(
                (nc) =>
                  nc?.id === comment.comment.id && nc?.postId === comment.postId
              )
          )
          .map((comment) => comment.comment);
      }
      setNotableComments(notableComments.slice(0, 3));
      setNotableCommentsHistory((prevHistory) => [
        ...prevHistory,
        notableComments,
      ]);

      const notablePostsData = data.notable_posts
        .map((postId) => selectedPostData.find((post) => post.id === postId))
        .filter((post) => post);

      setNotablePosts(notablePostsData);

      setHistoryIndex((prevIndex) => prevIndex + 1);

      setGeneratingInsights(false);
    } catch (error) {
      console.error('Error in loadInsights:', error);
      resetInsightStates();
    } finally {
      if (!signal?.aborted) {
        setGeneratingInsights(false);
      }
    }
  }, [
    setGeneratingInsights,
    searchedComments,
    vibeCheck,
    vibeCheckText,
    searched,
    search,
    selectedGroups,
    campaignFiltered,
    selectedAccounts,
    selectedTags,
    accountFiltered,
    tagFiltered,
    setInsightsSummary,
    setInsightTopics,
    setMoodDescriptors,
    setNotableComments,
    setNotableCommentsHistory,
    setNotablePosts,
    setHistoryIndex,
    resetInsightStates,
  ]);

  // Update the useEffect
  React.useEffect(() => {
    // Cleanup function to abort any pending requests
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []); // Empty dependency array as this is just for cleanup

  React.useEffect(() => {
    // Always reset insights while actively searching
    if (searching) {
      setHasInsights(false);
      setGeneratingInsights(false);
      return;
    }

    // Abort any existing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Need minimum number of comments to analyze
    if (!searchedComments || searchedComments.length <= 2) {
      setHasInsights(false);
      setGeneratingInsights(false);
      return;
    }

    const shouldGenerateInsights = 
      (search.trim() !== "") || 
      singlePost ||
      searched ||
      selected ||
      campaignFiltered ||
      tagFiltered ||
      accountFiltered;
    
    if (!shouldGenerateInsights) {
      setHasInsights(false);
      setGeneratingInsights(false);
    } else {
      abortControllerRef.current = new AbortController();
      setHasInsights(true);
      loadInsights(abortControllerRef.current.signal);
      // Remove this alert as it may interfere with cleanup
      // alert("loadInsights"); 
    }

  }, [
    campaignFiltered,
    tagFiltered,
    accountFiltered,
    searchedComments,
    // selectedPostData
  ]);

  const analyticsDefinitionText = `Vibe Score:\n
  The Vibe Score is Siftsy's proprietary metric that measures overall audience reception and interaction in comment sections on a scale of 1-10.
\n
Calculation\n

The score is calculated by averaging three key factors:
\n
-Sentiment\n
-Relevance\n
-Consensus of comments\n

Interpretation\n

-Scores closer to 10: Indicate a great atmosphere with a receptive, supportive audience\n
-Scores closer to 1: Suggest hostility and negative opinions/interactions\n

Application to Sponsored Content\n

For sponsored content, a comment section with a Vibe Score above 5 generally indicates:\n

-Positive reception\n
-Brand safety\n
-Increased brand awareness\n
-Potential for fostering purchase consideration and intent\n\n

Scoring Breakdown:\n
Sentiment (1-10)\n

Measures the emotional tone of comments from negative to neutral to positive.\n

Relevance (1-10)\n

Assesses how closely comments relate to the original post content:\n

-Low scores: Completely off-topic\n
-High scores: Directly responding to or paraphrasing the content\n

Consensus (1-10)\n

Evaluates how commenters interact with each other:\n

-Low scores: Hostile arguing\n
-High scores: Complete agreement and mutual support\n\n

Evaluation:\n

Siftsy employs AI technology and large language models to rank and score comments based on the above definitions, aiming for a Vibe Score accuracy within ±1 point of error.

`;
  const maxPercentage = (data) => {
    const percentages = data.map((item) => parseFloat(item.replace("%", "")));
    const maxIndex = percentages.indexOf(Math.max(...percentages));
    return {
      value: !isNaN(percentages[maxIndex])
        ? percentages[maxIndex] + "%"
        : "N/A",
      index: maxIndex,
    };
  };

  React.useEffect(() => {
    document.getElementById("vibe-check-section") &&
      document
        .getElementById("vibe-check-section")
        .addEventListener("mouseover", () => {
          setHovered(true);
        });

    document.getElementById("vibe-check-section") &&
      document
        .getElementById("vibe-check-section")
        .addEventListener("mouseout", () => {
          setHovered(false);
        });

    return () => {
      document.getElementById("vibe-check-section") &&
        document
          .getElementById("vibe-check-section")
          .removeEventListener("mouseover", () => {
            setHovered(true);
          });

      document.getElementById("vibe-check-section") &&
        document
          .getElementById("vibe-check-section")
          .removeEventListener("mouseout", () => {
            setHovered(false);
          });
    };
  }, []);

  const handleExportVibeCheck = async () => {
    //delay .5s
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (vibeCheckScreenshotRef.current) {
      // vibeCheckScreenshotRef.current.style.display = "flex";
      // deep copy metricsRef.current element
      const style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet?.insertRule(
        "body > div:last-child img { display: inline-block; }"
      );

      const options = {
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: null,
        scale: 4,
        letterRendering: true,
        imageTimeout: 5000,
        // Add these specific text rendering options
        async: true,
        removeContainer: false,
        scale: window.devicePixelRatio * 2, // Higher resolution
        // Ensure proper text rendering
        // windowWidth: metricsScreenshotRef.current.scrollWidth,
        // windowHeight: metricsScreenshotRef.current.scrollHeight
      };

      let canvas = await html2canvas(vibeCheckScreenshotRef.current, options);

      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `siftsy_vibe_check.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      style.remove();

      //   vibeCheckScreenshotRef.current.style.display = "none";
    }

    setExporting(false);
  };

  const handleExportInsights = async () => {
    setExporting(true);
  
    if (insightsRef.current) {
      const style = document.createElement("style");
      document.head.appendChild(style);
      
      // Enhanced styling rules for better text rendering
      style.sheet?.insertRule(`
        body > div:last-child img { 
          display: inline-block;
          image-rendering: -webkit-optimize-contrast;
        }
      `);
      
      style.sheet?.insertRule(`
        .topic-tag {
          display: inline-block !important;
          box-decoration-break: slice !important;
          -webkit-box-decoration-break: slice !important;
          padding: 1px 3px !important;
          position: relative !important;
          white-space: pre-wrap !important;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
          -webkit-font-smoothing: antialiased !important;
          -moz-osx-font-smoothing: grayscale !important;
          text-rendering: optimizeLegibility !important;
          font-weight: 600 !important;
          letter-spacing: -0.01em !important;
        }
      `);
  
      // style.sheet?.insertRule(`
      //   .MuiTypography-root {
      //     line-height: 1.5 !important;
      //   }
      // `);
  
      // Add a small delay to ensure styles are applied
      await new Promise((resolve) => setTimeout(resolve, 500));
  
      let canvas = await html2canvas(insightsRef.current, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: null,
        scrollX: 0,
        scrollY: 0,
        scale: 4,
        letterRendering: true,
        fontDisplay: 'auto',
        removeContainer: false,
        // Enhanced text rendering options
        onclone: (clonedDoc) => {
          const tags = clonedDoc.getElementsByClassName('topic-tag');
          Array.from(tags).forEach(tag => {
            tag.style.textRendering = 'optimizeLegibility';
            tag.style.fontSmooth = 'always';
          });
        }
      });
  
      const link = document.createElement("a");
      link.href = canvas.toDataURL('image/png', 1.0); // Added quality parameter
      link.download = `siftsy_vibe_check.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      style.remove();
    }
  
    setExporting(false);
  };

  const handleCopyInsights = async () => {
    setCopiedInsights(true);
    let insightsText = `Insights\n\n${insightsSummary.replace(
      /[\[\]**_]/g,
      ""
    )}\n\n${insightTopics}`;
    navigator.clipboard.writeText(insightsText);

    setTimeout(() => {
      setCopiedInsights(false);
    }, 3000);
  };

  const handleCopyMetrics = async () => {
    setCopied(true);

    navigator.clipboard.writeText(vibeCheckText);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  React.useEffect(() => {
    if (selectedPostData && selectedPostData.length > 0) {
      if (selected) {
        refreshVibeCheck(selectedComments);
      } else if (searched) {
        refreshVibeCheck(searchedComments);
      } else if (
        searchFiltered ||
        campaignFiltered ||
        tagFiltered ||
        accountFiltered
      ) {
        refreshVibeCheck(searchedComments);
      } else {
        refreshVibeCheck(fullCommentData);
      }
    }
  }, [
    selectedPostData,
    fullCommentData,
    searchedComments,
    selectedComments,
    selected,
    searched,
    searchFiltered,
    campaignFiltered,
    tagFiltered,
    accountFiltered,
  ]);

  const refreshVibeCheck = React.useCallback(
    async (commentData) => {
      const newVibeCheck = {
        vibeScore:
          searched ||
          selected ||
          searchFiltered ||
          campaignFiltered ||
          tagFiltered ||
          accountFiltered
            ? commentData && commentData.length > 0
              ? commentData
                  .filter(
                    (comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore != null &&
                      comment.comment.scores.sentimentScore != null &&
                      comment.comment.scores.relevanceScore != null
                  )
                  .reduce(
                    (sum, comment) =>
                      sum +
                      (comment.comment.scores.consensusScore +
                        comment.comment.scores.sentimentScore +
                        comment.comment.scores.relevanceScore) /
                        3,
                    0
                  ) /
                commentData.filter(
                  (comment) =>
                    comment.comment.scores &&
                    comment.comment.scores.consensusScore != null &&
                    comment.comment.scores.sentimentScore != null &&
                    comment.comment.scores.relevanceScore != null
                ).length
              : null
            : selectedPostData
                .filter((post) => post.data.totalComments > 0)
                .map((post) =>
                  post.reports &&
                  post.reports[0] &&
                  post.reports[0].vibeCheck &&
                  post.reports[0].vibeCheck.vibeScore
                    ? parseFloat(post.reports[0].vibeCheck.vibeScore)
                    : 0
                )
                .reduce((a, b) => a + b, 0) /
              selectedPostData.filter((post) => post.data.totalComments > 0)
                .length,

        consensusData: {
          consensusScore:
            searched ||
            selected ||
            searchFiltered ||
            campaignFiltered ||
            tagFiltered ||
            accountFiltered
              ? commentData && commentData.length > 0
                ? commentData
                    .filter(
                      (comment) =>
                        comment.comment.scores &&
                        comment.comment.scores.consensusScore != null
                    )
                    .reduce(
                      (sum, comment) =>
                        sum + comment.comment.scores.relevanceScore,
                      0
                    ) /
                  commentData.filter(
                    (comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore != null
                  ).length
                : null
              : selectedPostData
                  .filter((post) => post.data.totalComments > 0)
                  .map((post) =>
                    post.reports &&
                    post.reports[0] &&
                    post.reports[0].vibeCheck &&
                    post.reports[0].vibeCheck.consensusData
                      ? parseFloat(
                          post.reports[0].vibeCheck.consensusData.consensusScore
                        )
                      : 0
                  )
                  .reduce((a, b) => a + b, 0) /
                selectedPostData.filter((post) => post.data.totalComments > 0)
                  .length,
          positivePercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore
                        ? comment.comment.scores.consensusScore
                        : null
                    )
                    .filter(
                      (comment) => comment.comment.scores.consensusScore > 7
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore
                        ? comment.comment.scores.consensusScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
          neutralPercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore
                        ? comment.comment.scores.consensusScore
                        : null
                    )
                    .filter(
                      (comment) =>
                        comment.comment.scores.consensusScore >= 3 &&
                        comment.comment.scores.consensusScore <= 7
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore
                        ? comment.comment.scores.consensusScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
          negativePercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore
                        ? comment.comment.scores.consensusScore
                        : null
                    )
                    .filter(
                      (comment) => comment.comment.scores.consensusScore < 3
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.consensusScore
                        ? comment.comment.scores.consensusScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
        },
        relevanceData: {
          relevanceScore:
            searched ||
            selected ||
            searchFiltered ||
            campaignFiltered ||
            tagFiltered ||
            accountFiltered
              ? commentData && commentData.length > 0
                ? commentData
                    .filter(
                      (comment) =>
                        comment.comment.scores &&
                        comment.comment.scores.relevanceScore != null
                    )
                    .reduce(
                      (sum, comment) =>
                        sum + comment.comment.scores.relevanceScore,
                      0
                    ) /
                  commentData.filter(
                    (comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.relevanceScore != null
                  ).length
                : null
              : selectedPostData
                  .filter((post) => post.data.totalComments > 0)
                  .map((post) =>
                    post.reports &&
                    post.reports[0] &&
                    post.reports[0].vibeCheck &&
                    post.reports[0].vibeCheck.relevanceData
                      ? parseFloat(
                          post.reports[0].vibeCheck.relevanceData.relevanceScore
                        )
                      : 0
                  )
                  .reduce((a, b) => a + b, 0) /
                selectedPostData.filter((post) => post.data.totalComments > 0)
                  .length,
          positivePercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.relevanceScore
                        ? comment.comment.scores.relevanceScore
                        : null
                    )
                    .filter(
                      (comment) => comment.comment.scores.relevanceScore > 7
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.relevanceScore
                        ? comment.comment.scores.relevanceScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
          neutralPercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.relevanceScore
                        ? comment.comment.scores.relevanceScore
                        : null
                    )
                    .filter(
                      (comment) =>
                        comment.comment.scores.relevanceScore >= 3 &&
                        comment.comment.scores.relevanceScore <= 7
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.relevanceScore
                        ? comment.comment.scores.relevanceScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
          negativePercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.relevanceScore
                        ? comment.comment.scores.relevanceScore
                        : null
                    )
                    .filter(
                      (comment) => comment.comment.scores.relevanceScore < 3
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.relevanceScore
                        ? comment.comment.scores.relevanceScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
        },
        sentimentData: {
          sentimentScore:
            searched ||
            selected ||
            searchFiltered ||
            campaignFiltered ||
            tagFiltered ||
            accountFiltered
              ? commentData && commentData.length > 0
                ? commentData
                    .filter(
                      (comment) =>
                        comment.comment.scores &&
                        comment.comment.scores.sentimentScore != null
                    )
                    .reduce(
                      (sum, comment) =>
                        sum + comment.comment.scores.sentimentScore,
                      0
                    ) /
                  commentData.filter(
                    (comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.sentimentScore != null
                  ).length
                : null
              : selectedPostData
                  .filter((post) => post.data.totalComments > 0)
                  .map((post) =>
                    post.reports &&
                    post.reports[0] &&
                    post.reports[0].vibeCheck &&
                    post.reports[0].vibeCheck.sentimentData
                      ? parseFloat(
                          post.reports[0].vibeCheck.sentimentData.sentimentScore
                        )
                      : 0
                  )
                  .reduce((a, b) => a + b, 0) /
                selectedPostData.filter((post) => post.data.totalComments > 0)
                  .length,
          positivePercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.sentimentScore
                        ? comment.comment.scores.sentimentScore
                        : null
                    )
                    .filter(
                      (comment) => comment.comment.scores.sentimentScore > 7
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.sentimentScore
                        ? comment.comment.scores.sentimentScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
          neutralPercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.sentimentScore
                        ? comment.comment.scores.sentimentScore
                        : null
                    )
                    .filter(
                      (comment) =>
                        comment.comment.scores.sentimentScore >= 3 &&
                        comment.comment.scores.sentimentScore <= 7
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.sentimentScore
                        ? comment.comment.scores.sentimentScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
          negativePercentage:
            commentData && commentData.length > 0
              ? (
                  (commentData
                    .filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.sentimentScore
                        ? comment.comment.scores.sentimentScore
                        : null
                    )
                    .filter(
                      (comment) => comment.comment.scores.sentimentScore < 3
                    ).length /
                    commentData.filter((comment) =>
                      comment.comment.scores &&
                      comment.comment.scores.sentimentScore
                        ? comment.comment.scores.sentimentScore
                        : null
                    ).length) *
                  100
                ).toFixed(1) + "%"
              : null,
        },
      };
      setVibeCheck(commentData.length > 0 ? newVibeCheck : null);
      // alert("Vibe Check Refreshed");
    },
    [
      selectedPostData,
      fullCommentData,
      searchedComments,
      selectedComments,
      setVibeCheck,
      selected,
      searched,
      searchFiltered,
      campaignFiltered,
      tagFiltered,
      accountFiltered,
    ]
  );

  const vibeScore =
    vibeCheck && vibeCheck.vibeScore
      ? parseFloat(vibeCheck.vibeScore).toFixed(1)
      : 0;
  const sentimentScore =
    vibeCheck && vibeCheck.sentimentData.sentimentScore
      ? parseFloat(vibeCheck.sentimentData.sentimentScore).toFixed(1)
      : 0;
  const relevanceScore =
    vibeCheck && vibeCheck.relevanceData.relevanceScore
      ? parseFloat(vibeCheck.relevanceData.relevanceScore).toFixed(1)
      : 0;
  const consensusScore =
    vibeCheck && vibeCheck.consensusData.consensusScore
      ? parseFloat(vibeCheck.consensusData.consensusScore).toFixed(1)
      : 0;

  const vibeColor = (color) => {
    return color >= 7
      ? "#089616"
      : color >= 5
      ? "#74d52e"
      : color >= 3
      ? "#ffb93d"
      : "#fb3e3e";
  };

  // Update the styles object to include background highlight for topic tags
  const styles = {
    display: "inline",
    fontSize: "14px",
    "& a": {
      color: "#4728c4",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& strong": {
      fontWeight: 700,
      color: "#333",
    },
    "& em": {
      fontStyle: "italic",
      // textDecoration: "underline",
      color: "#666",
    },
    "& .topic-tag": {
      color: "#333",
      cursor: "pointer",
      fontWeight: 600,
      display: "inline",
      padding: "4px 4px",
      borderRadius: "4px",
      backgroundColor: "#f3f2ff", // Add default background color
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: "rgba(71, 40, 196, 0.1)",
        color: "#4728c4",
      },
      "&:active": {
        backgroundColor: "rgba(71, 40, 196, 0.2)",
      },
    },
    "& ul": {
      marginLeft: "20px",
      marginTop: "10px",
      marginBottom: "10px",
    },
    "& li": {
      marginBottom: "5px",
      color: "#555",
    },
  };

  // Update the handleDragEnd function
  const handleDragEnd = (result) => {
    // If there's no destination, the item was dragged outside - remove it
    if (!result.destination) {
      const newComments = notableComments.filter(
        (_, index) => index !== result.source.index
      );
      addToHistory(newComments);
      setNotableComments(newComments);
      return;
    }

    // Otherwise handle normal reordering
    const items = Array.from(notableComments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    addToHistory(items);
    setNotableComments(items);
  };

  const addToHistory = (newState) => {
    const newHistory = notableCommentsHistory.slice(0, historyIndex + 1);
    newHistory.push([...newState]);
    setNotableCommentsHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setNotableComments([...notableCommentsHistory[historyIndex - 1]]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < notableCommentsHistory.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setNotableComments([...notableCommentsHistory[historyIndex + 1]]);
    }
  };

  // Update the handleRemoveComment function to include event handling
  const handleRemoveComment = (index, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const newComments = notableComments.filter((_, i) => i !== index);
    addToHistory(newComments);
    setNotableComments(newComments);
  };

  // Function to handle copying comments
  const handleCopyNotableComments = () => {
    const commentText = notableComments
      .map((comment) => `${comment.username}: ${comment.text}`)
      .join("\n\n");
    navigator.clipboard.writeText(commentText);
    setCopiedInsights(true);
    setTimeout(() => setCopiedInsights(false), 3000);
  };

  // Function to handle screenshot of notable comments
  const handleScreenshotNotableComments = async () => {
    try {
      setExporting(true);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const wrapper = document.createElement("div");
      wrapper.style.position = "absolute";
      wrapper.style.left = "-9999px";
      wrapper.style.backgroundColor = "#ffffff";
      wrapper.style.borderRadius = "12px";
      wrapper.style.padding = "16px";
      wrapper.style.width = "600px";

      const notableCommentsSection = document.getElementById(
        "notable-comments-section"
      );
      if (!notableCommentsSection || hideNotableComments) {
        // Add check for hideNotableComments
        console.error("Notable comments section not found or hidden");
        setExporting(false);
        return;
      }

      const clone = notableCommentsSection.cloneNode(true);

      // More specific removal of hover elements
      const hoverElements = clone.querySelectorAll(
        [
          '[style*="position: absolute"]',
          ".MuiButtonGroup-root", // Target button groups
          '[id="metrics-actions"]', // Target specific IDs
          ".hover-actions", // Add this class to your hover elements
        ].join(",")
      );

      hoverElements.forEach((el) => el.remove());

      // Also remove any elements with opacity transitions (likely hover effects)
      const hoverTransitionElements = clone.querySelectorAll(
        '[style*="transition"]'
      );
      hoverTransitionElements.forEach((el) => {
        if (el.style.transition.includes("opacity")) {
          el.remove();
        }
      });

      wrapper.appendChild(clone);
      document.body.appendChild(wrapper);

      // Pre-load images and handle avatars
      const images = wrapper.getElementsByTagName('img');
      const avatars = wrapper.getElementsByClassName('MuiAvatar-root');

      // Process avatars first
      Array.from(avatars).forEach(avatar => {
        avatar.style.cssText = `
          display: flex !important;
          width: 40px !important;
          height: 40px !important;
          border-radius: 50% !important;
          margin-right: 14px !important;
          overflow: hidden !important;
          background-color: #f5f5f5 !important;
        `;

        const img = avatar.querySelector('img');
        if (img) {
          img.style.cssText = `
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            display: block !important;
          `;
          img.crossOrigin = 'anonymous';
        }
      });

      // Ensure all images are loaded
      await Promise.all([
        ...Array.from(images).map(img => {
          if (img.complete) return Promise.resolve();
          return new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
            // Force image reload
            const currentSrc = img.src;
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
            setTimeout(() => {
              img.src = currentSrc;
            }, 10);
          });
        }),
        new Promise(resolve => setTimeout(resolve, 500)) // Additional delay for rendering
      ]);


      try {
        const canvas = await html2canvas(wrapper, {
          useCORS: true,
          allowTaint: true,
          backgroundColor: "#ffffff",
          scale: window.devicePixelRatio * 2,
          logging: false,
          removeContainer: true,
          width: 600, // Match wrapper width
          onclone: (clonedDoc) => {
            const images = clonedDoc.getElementsByTagName("img");
            Array.from(images).forEach((img) => {
              img.crossOrigin = "anonymous";
              img.style.display = "inline-block";
            });

            const avatars = clonedDoc.getElementsByClassName("MuiAvatar-root");
            Array.from(avatars).forEach((avatar) => {
              avatar.style.display = "flex";
              avatar.style.width = "40px";
              avatar.style.height = "40px";
              avatar.style.borderRadius = "50%";
              avatar.style.marginRight = "14px";
            });
          },
        });

        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `notable_comments_${Date.now()}.png`;
          document.body.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(url);
        }, "image/png");
      } catch (error) {
        console.error("Screenshot generation failed:", error);
        throw error;
      }
    } catch (error) {
      console.error("Screenshot process failed:", error);
    } finally {
      const wrapper = document.querySelector('[style*="left: -9999px"]');
      if (wrapper && document.body.contains(wrapper)) {
        document.body.removeChild(wrapper);
      }
      setExporting(false);
    }
  };

  // Individual comment screenshot handler
  const handleScreenshotComment = async (commentId) => {
    if (hideNotableComments) {
      console.log("Notable comments are hidden");
      return;
    }

    try {
      setExporting(true);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Create wrapper with specific dimensions and styling
      const wrapper = document.createElement('div');
      wrapper.style.cssText = `
        position: absolute;
        left: -9999px;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 16px;
        width: 600px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      `;

      const commentElement = document.getElementById(`notable-comment-${commentId}`);
      if (!commentElement) {
        console.error("Comment element not found");
        setExporting(false);
        return;
      }

      const clone = commentElement.cloneNode(true);

      // Remove hover elements
      const elementsToRemove = clone.querySelectorAll([
        '[style*="position: absolute"]',
        '.MuiButtonGroup-root',
        '[id="metrics-actions"]',
        '.hover-actions',
        '.comment-actions'
      ].join(','));
      elementsToRemove.forEach(el => el.remove());

      wrapper.appendChild(clone);
      document.body.appendChild(wrapper);

      // Handle all images including avatars
      const images = wrapper.getElementsByTagName('img');
      const avatars = wrapper.getElementsByClassName('MuiAvatar-root');

      // Process avatars first
      Array.from(avatars).forEach(avatar => {
        avatar.style.cssText = `
          display: flex !important;
          width: 40px !important;
          height: 40px !important;
          border-radius: 50% !important;
          margin-right: 14px !important;
          overflow: hidden !important;
          background-color: #f5f5f5 !important;
        `;

        const img = avatar.querySelector('img');
        if (img) {
          img.style.cssText = `
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            display: block !important;
          `;
          img.crossOrigin = 'anonymous';
        }
      });

      // Ensure all images are loaded
      await Promise.all([
        ...Array.from(images).map(img => {
          if (img.complete) return Promise.resolve();
          return new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
            // Force image reload
            const currentSrc = img.src;
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
            setTimeout(() => {
              img.src = currentSrc;
            }, 10);
          });
        }),
        new Promise(resolve => setTimeout(resolve, 500)) // Additional delay for rendering
      ]);

      // Generate screenshot
      const canvas = await html2canvas(wrapper, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: '#ffffff',
        scale: window.devicePixelRatio * 2,
        logging: false,
        width: 600,
        onclone: (clonedDoc) => {
          const clonedWrapper = clonedDoc.querySelector(`#notable-comment-${commentId}`);
          if (clonedWrapper) {
            // Re-apply styles to cloned elements
            const clonedAvatars = clonedWrapper.getElementsByClassName('MuiAvatar-root');
            Array.from(clonedAvatars).forEach(avatar => {
              avatar.style.cssText = `
                display: flex !important;
                width: 40px !important;
                height: 40px !important;
                border-radius: 50% !important;
                margin-right: 14px !important;
                overflow: hidden !important;
                background-color: #f5f5f5 !important;
              `;

              const img = avatar.querySelector('img');
              if (img) {
                img.style.cssText = `
                  width: 100% !important;
                  height: 100% !important;
                  object-fit: cover !important;
                  display: block !important;
                `;
                img.crossOrigin = 'anonymous';
              }
            });
          }
        }
      });

      // Download the image
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `comment_${commentId}_${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      }, 'image/png');

    } catch (error) {
      console.error("Screenshot process failed:", error);
    } finally {
      // Clean up
      const wrapper = document.querySelector('[style*="left: -9999px"]');
      if (wrapper && document.body.contains(wrapper)) {
        document.body.removeChild(wrapper);
      }
      setExporting(false);
    }
  };

  // Helper function for avatar color
  const stringToColor = (string) => {
    if (!string) return "#96CEB4";
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colors = [
      "#FF6B6B",
      "#4ECDC4",
      "#45B7D1",
      "#96CEB4",
      "#FFEEAD",
      "#D4A5A5",
      "#9B59B6",
      "#3498DB",
      "#E67E22",
      "#2ECC71",
    ];
    return colors[Math.abs(hash % colors.length)];
  };

  // Add these new functions for individual comment actions
  const handleCopyComment = (comment) => {
    const commentText = `${comment.username}: ${comment.text}`;
    navigator.clipboard.writeText(commentText);
    setCopiedInsights(true);
    setTimeout(() => setCopiedInsights(false), 3000);
  };

  // Update the handleDrop function
  const handleDrop = (comment) => {
    try {
      // Parse the comment if it's a string
      const commentData =
        typeof comment === "string" ? JSON.parse(comment) : comment;

      // Check if this comment is already in notable comments
      if (!notableComments.some((c) => c.id === commentData.id)) {
        // Add new comment to existing ones (no 3 comment limit when dragging)
        const newComments = [...notableComments, commentData];

        // Add to history for undo/redo
        addToHistory(newComments);

        // Update state
        setNotableComments(newComments);
      }
    } catch (error) {
      console.error("Error handling dropped comment:", error);
    }
  };

  return quickView ? (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <SpeedRounded sx={{ fontSize: 16, color: "#888" }} />
        {!exporting &&
          (selected ||
            searched ||
            searchFiltered ||
            campaignFiltered ||
            tagFiltered ||
            accountFiltered) && (
            <Chip
              size="small"
              label={
                selected
                  ? `${selectedComments.length} Selected`
                  : searched
                  ? `Search`
                  : campaignFiltered
                  ? "Campaign"
                  : tagFiltered
                  ? "Content Tags"
                  : accountFiltered
                  ? "Creator"
                  : searchFiltered
                  ? `Filtered`
                  : ""
              }
              sx={{
                backgroundColor: "#edecfe",
                color: "#666",
                fontSize: isMobile ? 9 : 12,
                fontWeight: 600,
              }}
            />
          )}
        <Typography
          variant="body2"
          align="center"
          sx={{ color: vibeColor(vibeScore), fontWeight: 700, fontSize: 16 }}
        >
          {vibeScore}
        </Typography>

        <LinearProgress
          variant="determinate"
          value={vibeScore * 10}
          sx={{
            borderRadius: 30,
            height: 10,
            backgroundColor: `${vibeColor(vibeScore)}50`, // Adjusted to RGBA with reduced opacity
            width: "60%",
            border: "transparent",
            transition: "width 0.5s ease-in-out", // Add this line
            "& .MuiLinearProgress-barColorPrimary": {
              background: vibeColor(vibeScore),
            },
          }}
        />
      </Box>
      <Box>
      
      </Box>
    </Box>
  ) : (
    <>
      <Dialog
        open={openImage}
        onClose={() => setOpenImage(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 20 } }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              position: "absolute",
              alignItems: "center",
              top: 10,
              right: 10,
            }}
          >
            <Tooltip title="Download as Image" placement="top">
              <IconButton
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = "/siftsy_score_breakdown.png"; // Path to the image
                  link.download = "siftsy_score_breakdown.png"; // Name for the downloaded file
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }} // Added the click handler to download the image
                color="primary"
              >
                <ArrowDownOnSquareIcon
                  style={{ height: 25, color: "#5C6078" }}
                />
              </IconButton>
            </Tooltip>

            {copied ? (
              <Typography variant="body2" color="#089616">
                Copied!
              </Typography>
            ) : (
              <Tooltip title="Copy as Text" placement="top">
                <IconButton
                  onClick={async () => {
                    await navigator.clipboard.writeText(
                      analyticsDefinitionText
                    );
                    setCopied(true); // Trigger the animation
                    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
                  }}
                  color="primary"
                >
                  <DocumentDuplicateIcon
                    style={{ height: 25, color: "#5C6078" }}
                  />
                </IconButton>
              </Tooltip>
            )}

            <IconButton onClick={() => setOpenImage(false)} color="primary">
              <XMarkIcon style={{ height: 25, color: "#5C6078" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <img
            src="/siftsy_score_breakdown.png"
            alt="Siftsy Score Breakdown"
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <Box
        id="vibe-check-section"
        sx={{
          display: "flex",
          flexDirection: fullTable && !isMobile ? "column" : "row",
          gap: isMobile ? 1 : 2,
          zIndex: 1,
          position: "relative",
        }}
        ref={vibeCheckScreenshotRef}
      >
        <Box
          sx={{
            width: fullTable ? null : "45%", // height:140
            position: "relative",
          }}
        >
          <Collapse
            in={searchedComments.length > 0}
            timeout="auto"
            unmountOnExit
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #DDD",
                borderRadius: 3,
                p: isMobile ? 1 : 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                minHeight: isMobile ? 130 : 180,

                // height:140
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* {!isMobile && <SpeedRounded sx={{ fontSize: 20, color: "#888" }} />} */}

                <Typography
                  variant="body2"
                  align="left"
                  sx={{ color: "#5C6078", fontWeight: 700 }}
                >
                  {isMobile ? "Vibe" : "Vibe Score"}
                </Typography>

                {!exporting && (
                  <IconButton
                    size="small"
                    sx={{ ml: -1 }}
                    onClick={() => setOpenImage(!openImage)} // Toggle image visibility
                  >
                    <InformationCircleIcon
                      style={{ height: 16, width: 16, paddingBottom: 1 }}
                    />
                  </IconButton>
                )}

                {!exporting &&
                  (selected ||
                    searched ||
                    searchFiltered ||
                    campaignFiltered ||
                    tagFiltered ||
                    accountFiltered) && (
                    <Chip
                      size="small"
                      label={
                        selected
                          ? `${selectedComments.length} Selected`
                          : searched
                          ? `Search`
                          : campaignFiltered
                          ? "Campaign"
                          : tagFiltered
                          ? "Content Tags"
                          : accountFiltered
                          ? "Creator"
                          : searchFiltered
                          ? `Filtered`
                          : ""
                      }
                      sx={{
                        backgroundColor: "#edecfe",
                        color: "#666",
                        fontSize: isMobile ? 9 : 12,
                        fontWeight: 600,
                      }}
                    />
                  )}

                <Box sx={{ flexGrow: 1 }} />
              </Box>

              {!searching && vibeCheck && vibeCheck.vibeScore ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    transform: isMobile
                      ? "translate(0%, 0%) scale(.9)"
                      : "translate(0%, 0%) scale(1.3)",
                    height: "100%",
                    mt: isMobile ? -2 : 1,
                    mb: isMobile ? -3 : 0,
                  }}
                >
                  <VibeScorer vibeScore={parseFloat(vibeCheck.vibeScore)} 
                  moodDescriptors={moodDescriptors}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "90%",
                  }}
                >
                  <Skeleton variant="circular" width={125} height={125} />
                </Box>
              )}

              {vibeCheck && expanded && (
                <Grid container spacing={2}>
                  {vibeCheck.sentimentData && (
                    <Grid item xs={4}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography fontSize={10} fontWeight={600} color="#666">
                          Sentiment
                        </Typography>
                        <Typography fontSize={20} fontWeight={600} color="#555">
                          {
                            maxPercentage([
                              vibeCheck.sentimentData.positivePercentage,
                              vibeCheck.sentimentData.neutralPercentage,
                              vibeCheck.sentimentData.negativePercentage,
                            ]).value
                          }
                        </Typography>
                        <Chip
                          size="small"
                          sx={{
                            borderRadius: 2,
                            fontSize: 10,
                            opacity: 0.7,
                            width: 70,
                            height: 22,
                            userSelect: "none",
                            "& .MuiChip-label": {
                              width: "100%", // Make the label take full width of the chip
                              padding: 0, // Remove default padding
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              opacity: 1,
                            },
                            backgroundColor:
                              maxPercentage([
                                vibeCheck.sentimentData.positivePercentage,
                                vibeCheck.sentimentData.neutralPercentage,
                                vibeCheck.sentimentData.negativePercentage,
                              ]).index === 0
                                ? "#089616"
                                : maxPercentage([
                                    vibeCheck.sentimentData.positivePercentage,
                                    vibeCheck.sentimentData.neutralPercentage,
                                    vibeCheck.sentimentData.negativePercentage,
                                  ]).index === 1
                                ? "#74d52e"
                                : "#fb3e3e",
                            color: "#fff",
                          }}
                          label={
                            maxPercentage([
                              vibeCheck.sentimentData.positivePercentage,
                              vibeCheck.sentimentData.neutralPercentage,
                              vibeCheck.sentimentData.negativePercentage,
                            ]).index === 0
                              ? "Positive"
                              : maxPercentage([
                                  vibeCheck.sentimentData.positivePercentage,
                                  vibeCheck.sentimentData.neutralPercentage,
                                  vibeCheck.sentimentData.negativePercentage,
                                ]).index === 1
                              ? "Neutral"
                              : "Negative"
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                  {vibeCheck.relevanceData && (
                    <Grid item xs={4}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography fontSize={10} fontWeight={600} color="#666">
                          Relevance
                        </Typography>
                        <Typography fontSize={20} fontWeight={600} color="#555">
                          {
                            maxPercentage([
                              vibeCheck.relevanceData.positivePercentage,
                              vibeCheck.relevanceData.neutralPercentage,
                              vibeCheck.relevanceData.negativePercentage,
                            ]).value
                          }
                        </Typography>
                        <Chip
                          size="small"
                          sx={{
                            borderRadius: 2,
                            fontSize: 10,
                            opacity: 0.7,
                            width: 70,
                            height: 22,
                            backgroundColor:
                              maxPercentage([
                                vibeCheck.relevanceData.positivePercentage,
                                vibeCheck.relevanceData.neutralPercentage,
                                vibeCheck.relevanceData.negativePercentage,
                              ]).index === 0
                                ? "#089616"
                                : maxPercentage([
                                    vibeCheck.relevanceData.positivePercentage,
                                    vibeCheck.relevanceData.neutralPercentage,
                                    vibeCheck.relevanceData.negativePercentage,
                                  ]).index === 1
                                ? "#74d52e"
                                : "#fb3e3e",
                            color: "#fff",
                          }}
                          label={
                            maxPercentage([
                              vibeCheck.relevanceData.positivePercentage,
                              vibeCheck.relevanceData.neutralPercentage,
                              vibeCheck.relevanceData.negativePercentage,
                            ]).index === 0
                              ? "On-Topic"
                              : maxPercentage([
                                  vibeCheck.relevanceData.positivePercentage,
                                  vibeCheck.relevanceData.neutralPercentage,
                                  vibeCheck.relevanceData.negativePercentage,
                                ]).index === 1
                              ? "General"
                              : "Off-Topic"
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                  {vibeCheck.consensusData &&
                    vibeCheck.consensusData.consensusScore && (
                      <Grid item xs={4}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            fontSize={10}
                            fontWeight={600}
                            color="#666"
                          >
                            Consensus
                          </Typography>
                          <Typography
                            fontSize={20}
                            fontWeight={600}
                            color="#555"
                          >
                            {
                              maxPercentage([
                                vibeCheck.consensusData.positivePercentage,
                                vibeCheck.consensusData.neutralPercentage,
                                vibeCheck.consensusData.negativePercentage,
                              ]).value
                            }
                          </Typography>
                          <Chip
                            size="small"
                            sx={{
                              borderRadius: 2,
                              fontSize: 10,
                              opacity: 0.7,
                              width: 70,
                              height: 22,
                              backgroundColor:
                                maxPercentage([
                                  vibeCheck.consensusData.positivePercentage,
                                  vibeCheck.consensusData.neutralPercentage,
                                  vibeCheck.consensusData.negativePercentage,
                                ]).index === 0
                                  ? "#089616"
                                  : maxPercentage([
                                      vibeCheck.consensusData
                                        .positivePercentage,
                                      vibeCheck.consensusData.neutralPercentage,
                                      vibeCheck.consensusData
                                        .negativePercentage,
                                    ]).index === 1
                                  ? "#74d52e"
                                  : "#fb3e3e",
                              color: "#fff",
                            }}
                            label={
                              maxPercentage([
                                vibeCheck.consensusData.positivePercentage,
                                vibeCheck.consensusData.neutralPercentage,
                                vibeCheck.consensusData.negativePercentage,
                              ]).index === 0
                                ? "Agreement"
                                : maxPercentage([
                                    vibeCheck.consensusData.positivePercentage,
                                    vibeCheck.consensusData.neutralPercentage,
                                    vibeCheck.consensusData.negativePercentage,
                                  ]).index === 1
                                ? "Mixed"
                                : "Argument"
                            }
                          />
                        </Box>
                      </Grid>
                    )}
                </Grid>
              )}

              <ClickAwayListener onClickAway={() => setOpenExplainer(false)}>
                <Popper
                  open={openExplainer}
                  anchorEl={explainerButtonRef.current}
                  placement="top"
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: "0px, 10px",
                    },
                  }}
                  style={{ zIndex: 2000 }}
                >
                  <Paper
                    sx={{
                      background: "#f7f7f7",
                      borderRadius: 3,
                      width: 300,

                      p: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{ p: 1, background: "#f7f7f7", borderRadius: 3 }}
                    >
                      <InfoOutlined sx={{ fontSize: 16, color: "#888" }} />
                      &nbsp; The <b>vibe score</b> is Siftsy's proprietary
                      metric that measures overall audience reception in comment
                      sections on a scale of 1-10. The score is calculated by
                      averaging the sentiment, relevance, and consensus of the
                      comments.
                      <br />
                      <br />
                      Scores <b>closer to 10</b> imply a great atmosphere and a
                      receptive audience, while scores closer to 1 imply
                      hostility and "hatewatching".
                      <br />
                      <br />
                      Content with comment sections scoring <b>above a 5</b> are
                      generally great places for sponsors to invest their
                      marketing budgets, while lower ranking content often means
                      controversy and is not recommended for brand safety,
                      positive brand awareness, or to foster purchase
                      consideration & intent.
                    </Typography>
                  </Paper>
                </Popper>
              </ClickAwayListener>
            </Box>
          </Collapse>
        </Box>
        <Box
          sx={{
            width: fullTable && !isMobile ? null : "80%",
            position: "relative",
          }}
        >
          <Collapse
            in={searchedComments.length > 0}
            timeout="auto"
            unmountOnExit
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #DDD",
                borderRadius: 3,
                p: isMobile ? 1 : 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                minHeight: isMobile ? 130 : 180,

                // height:140
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* {!isMobile && (
                <BarChartOutlined
                  sx={{ fontSize: 20, color: "#888" }}
                />
              )} */}

                <Typography
                  variant="body2"
                  align="left"
                  sx={{ color: "#5C6078", fontWeight: 700 }}
                >
                  Breakdown
                </Typography>

                {!exporting && (
                  <IconButton
                    size="small"
                    sx={{ ml: -1 }}
                    onClick={() => setOpenImage(!openImage)} // Toggle image visibility
                  >
                    <InformationCircleIcon
                      style={{ height: 16, width: 16, paddingBottom: 1 }}
                    />
                  </IconButton>
                )}

                {!exporting &&
                  (selected ||
                    searched ||
                    searchFiltered ||
                    campaignFiltered ||
                    tagFiltered ||
                    accountFiltered) && (
                    <Chip
                      size="small"
                      label={
                        selected
                          ? `${selectedComments.length} Selected`
                          : searched
                          ? `Search`
                          : campaignFiltered
                          ? "Campaign"
                          : tagFiltered
                          ? "Content Tags"
                          : accountFiltered
                          ? "Creator"
                          : searchFiltered
                          ? `Filtered`
                          : ""
                      }
                      sx={{
                        backgroundColor: "#edecfe",
                        color: "#666",
                        fontSize: isMobile ? 9 : 12,
                        fontWeight: 600,
                      }}
                    />
                  )}

                <Box sx={{ flexGrow: 1 }} />

                {
                  // !screenshot && hovered &&
                  !exporting && (
                    // <ButtonGroup
                    //   size="small"
                    //   className="hover-actions" // Add this class
                    //   sx={{
                    //     background: "#f7f7f7",
                    //     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    //     padding: "5px 10px",
                    //     gap: ".5rem",
                    //     borderRadius: 30,
                    //   }}
                    //   id="metrics-actions"
                    // >
                    <>
                      <Tooltip title="Copy Vibe Check as Text" placement="top">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={handleCopyMetrics}
                        >
                          {copied ? (
                            <CheckCircleIcon
                              style={{ width: 18, height: 18 }}
                            />
                          ) : (
                            <DocumentDuplicateIcon
                              style={{
                                height: 20,
                                width: 20,
                                color: "#5C6078",
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title="Download Vibe Check As Image"
                        placement="top"
                      >
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={handleExportVibeCheck}
                        >
                          {exporting ? (
                            <CircularProgress size={18} />
                          ) : (
                            <ArrowDownOnSquareIcon
                              style={{
                                height: 20,
                                width: 20,
                                color: "#5C6078",
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </>
                    // </ButtonGroup>
                  )
                }
                {/* <IconButton size="small" color="primary">
                <MoreHorizOutlined sx={{ fontSize: 20, color: "#fff" }} />
              </IconButton> */}
                {/* {!exporting && (
              <IconButton
                ref={explainerButtonRef}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenExplainer(!openExplainer);
                }}
              >
                <InfoOutlined sx={{ fontSize: 20, color: "#888" }} />
              </IconButton>
            )} */}
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Grid container spacing={1}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      // ml: 3,
                      // mr: 3,

                      pr: 1,
                      pl: 1,
                      flexGrow: 1,
                      gap: 1,
                    }}
                  >
                    {(!searching || searchedComments.length > 0) &&
                    vibeCheck &&
                    vibeCheck.sentimentData ? (
                      <DataBar
                        isMobile={isMobile}
                        exporting={exporting}
                        label="Sentiment"
                        score={sentimentScore}
                        label1="Positive"
                        label2="Neutral"
                        label3="Negative"
                        data1={
                          vibeCheck &&
                          vibeCheck.sentimentData.positivePercentage
                            ? vibeCheck.sentimentData.positivePercentage
                            : null
                        }
                        data2={
                          vibeCheck && vibeCheck.sentimentData.neutralPercentage
                            ? vibeCheck.sentimentData.neutralPercentage
                            : null
                        }
                        data3={
                          vibeCheck &&
                          vibeCheck.sentimentData.negativePercentage
                            ? vibeCheck.sentimentData.negativePercentage
                            : null
                        }
                        color="#4828C5"
                        description="Sentiment is a measure of how positive or negative the comments are. A higher sentiment score means that the comments are more positive. A lower sentiment score means that the comments are more negative."
                      />
                    ) : (
                      <Box sx={{ mt: 2 }}>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          sx={{ borderRadius: 4 }}
                        />
                      </Box>
                    )}
                    {(!searching || searchedComments.length > 0) &&
                    vibeCheck &&
                    vibeCheck.relevanceData ? (
                      <DataBar
                        isMobile={isMobile}
                        exporting={exporting}
                        label="Relevance"
                        score={relevanceScore}
                        color="#4828C5"
                        data1={
                          vibeCheck &&
                          vibeCheck.relevanceData.positivePercentage
                            ? vibeCheck.relevanceData.positivePercentage
                            : null
                        }
                        data2={
                          vibeCheck && vibeCheck.relevanceData.neutralPercentage
                            ? vibeCheck.relevanceData.neutralPercentage
                            : null
                        }
                        data3={
                          vibeCheck &&
                          vibeCheck.relevanceData.negativePercentage
                            ? vibeCheck.relevanceData.negativePercentage
                            : null
                        }
                        label1="On-Topic"
                        label2="General"
                        label3="Off-Topic"
                        description="Relevance is a measure of how relevant the comments are to the video. A higher relevance score means that the comments are more relevant to the video."
                      />
                    ) : (
                      <Box sx={{ mt: 2 }}>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          sx={{ borderRadius: 4 }}
                        />
                      </Box>
                    )}
                    {(!searching || searchedComments.length > 0) &&
                    vibeCheck &&
                    vibeCheck.consensusData &&
                    vibeCheck.consensusData.consensusScore ? (
                      <DataBar
                        isMobile={isMobile}
                        exporting={exporting}
                        label="Consensus"
                        score={consensusScore}
                        color="#4828C5"
                        label1="Agreement"
                        label2="Mixed"
                        label3="Argument"
                        data1={
                          vibeCheck &&
                          vibeCheck.consensusData.positivePercentage
                            ? vibeCheck.consensusData.positivePercentage
                            : null
                        }
                        data2={
                          vibeCheck && vibeCheck.consensusData.neutralPercentage
                            ? vibeCheck.consensusData.neutralPercentage
                            : null
                        }
                        data3={
                          vibeCheck &&
                          vibeCheck.consensusData.negativePercentage
                            ? vibeCheck.consensusData.negativePercentage
                            : null
                        }
                        description={
                          vibeCheck &&
                          vibeCheck.consensusData &&
                          vibeCheck.consensusData.info
                            ? vibeCheck.consensusData.info
                            : `Consensus is a measure of how much the comments agree with each other. A higher consensus score means that the comments are more similar to each other. A lower consensus score means that the comments are more diverse.`
                        }
                      />
                    ) : (
                      <Box sx={{ mt: 2 }}>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          sx={{ borderRadius: 4 }}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>

      {/* {hasInsights ? ( */}
      <Collapse in={hasInsights} timeout={300} unmountOnExit>
        <Box
          ref={insightsRef}
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #DDD",
            borderRadius: 3,
            p: isMobile ? 1 : 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: isMobile ? 1 : 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
            // onClick={() => setShowInsights(!showInsights)}
          >
            <Typography
              variant="body2"
              align="left"
              sx={{ color: "#5C6078", fontWeight: 700 }}
            >
              Insights
            </Typography>
            {!exporting && (
              <IconButton
                size="small"
                sx={{ ml: -1 }}
                onClick={() => setOpenImage(!openImage)} // Toggle image visibility
              >
                <InformationCircleIcon
                  style={{ height: 16, width: 16, paddingBottom: 1 }}
                />
              </IconButton>
            )}

            {!exporting &&
              (selected ||
                searched ||
                searchFiltered ||
                campaignFiltered ||
                tagFiltered ||
                accountFiltered) && (
                <Chip
                  size="small"
                  label={
                    selected
                      ? `${selectedComments.length} Selected`
                      : searched
                      ? `Search`
                      : campaignFiltered
                      ? "Campaign"
                      : tagFiltered
                      ? "Content Tags"
                      : accountFiltered
                      ? "Creator"
                      : searchFiltered
                      ? `Filtered`
                      : ""
                  }
                  sx={{
                    backgroundColor: "#edecfe",
                    color: "#666",
                    fontSize: isMobile ? 9 : 12,
                    fontWeight: 600,
                  }}
                />
              )}

            <Box sx={{ flexGrow: 1 }} />

            {!exporting && (
              <Box sx={{ display: "flex", flexDirection: "row", gap: 0 }}>
                <Tooltip title="Copy Insights As Text" placement="top">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleCopyInsights}
                    disabled={generatingInsights}
                  >
                    {copiedInsights ? (
                      <CheckCircleIcon
                        color="success"
                        style={{ width: 18, height: 18 }}
                      />
                    ) : (
                      <DocumentDuplicateIcon
                        style={{ height: 20, width: 20, color: "#5C6078" }}
                      />
                    )}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Download Insights As Image" placement="top">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleExportInsights}
                    disabled={generatingInsights}
                  >
                    {exporting ? (
                      <CircularProgress size={18} />
                    ) : (
                      <ArrowDownOnSquareIcon
                        style={{ height: 20, width: 20, color: "#5C6078" }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Refresh Insights" placement="top">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => loadInsights(abortControllerRef.current.signal)}
                    disabled={generatingInsights}
                  >
                    <ArrowPathIcon style={{ height: 20, color: "#5C6078" }} />
                  </IconButton>
                </Tooltip>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setShowInsights(!showInsights)}
                >
                  {showInsights ? (
                    <ChevronUpIcon style={{ height: 20, color: "#5C6078" }} />
                  ) : (
                    <ChevronDownIcon style={{ height: 20, color: "#5C6078" }} />
                  )}
                </IconButton>
              </Box>
            )}
          </Box>

          {showInsights && (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {/* <Collapse in={generatingInsights} timeout={400} unmountOnExit> */}
              <Fade in={generatingInsights} timeout={300} unmountOnExit>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0,
                      position: "relative",
                      marginTop: generatingInsights ? 0 : -3,
                      height: generatingInsights ? "auto" : 0,
                      opacity: generatingInsights ? 1 : 0,
                      transition: "all 300ms ease-in-out",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      width={"100%"}
                      height={25}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      width={"100%"}
                      height={25}
                      animation="wave"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        mt: 1,
                        animation: "fadeIn 0.5s ease-in-out",
                        "@keyframes fadeIn": {
                          "0%": {
                            opacity: 0,
                            transform: "translateY(10px)",
                          },
                          "100%": {
                            opacity: 1,
                            transform: "translateY(0)",
                          },
                        },
                      }}
                    >
                      <Typography variant="h6" color="#999">
                        {/* Searching for comments about <br/> */}
                        <Typography
                          variant="h6"
                          color="text.secondary"
                          fontSize={18}
                          fontStyle="italic"
                          fontWeight={700}
                          style={{
                            color: "text.secondary",
                            display: "inline",
                            animation: "pulse 2s infinite",
                          }}
                          sx={{
                            "@keyframes pulse": {
                              "0%": {
                                opacity: 1,
                              },
                              "50%": {
                                opacity: 0.6,
                              },
                              "100%": {
                                opacity: 1,
                              },
                            },
                            // Add fade animation when verb changes
                            animation: `pulse 2s infinite, fadeChange 0.5s ease-in-out`,
                            "@keyframes fadeChange": {
                              "0%": {
                                opacity: 0,
                                transform: "translateY(5px)",
                              },
                              "100%": {
                                opacity: 1,
                                transform: "translateY(0)",
                              },
                            },
                            // Use key prop to trigger animation on change
                            key: currentVerb,
                          }}
                        >
                          {currentVerb}...
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Fade>
                {/* </Collapse> */}
                <Fade in={!generatingInsights && insightTopics && insightTopics.length > 0} timeout={600} unmountOnExit delay={300}>

                <Box
                  sx={{
                    flexDirection: "column",
                    height: "auto",
                    display: !generatingInsights && insightsSummary?.length > 0 ? "flex" : "none",
                    flexDirection: "column",
                    gap: 2,
                    opacity: !generatingInsights && insightsSummary?.length > 0 ? 1 : 0,
                    transition: "all 300ms ease-in-out",
                    
                  }}
                >

                      {/* Add Summary heading */}
                      <Box>

      
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666",
                            fontWeight: 600,
                            fontSize: 12,
                            mb: 1,
                          }}
                        >                <SparklesIcon
                        style={{
                          height: 12,
                          width: 12,
                          color: "#5C6078",
                          display: "inline",
                          marginRight: 4,
                        }}
                      />
                          Summary
                        </Typography>
                        <Typography
                          variant="body2"
                          align="left"
                          sx={{ color: "#333", ...styles }}
                        >
           
                          <Typography
                            variant="body2"
                            align="left"
                            sx={{ color: "#333", ...styles }}
                            dangerouslySetInnerHTML={{
                              __html: parseMarkdown(insightsSummary),
                            }}
                            onClick={(e) => {
                              if (e.target.classList.contains("topic-tag")) {
                                const topic = e.target.dataset.topic;
                                if (topic && searchComments) {
                                  searchComments(topic);
                                }
                              }
                            }}
                          />
                        </Typography>
                      </Box>                    


                      {/* Add Key Themes heading */}
                      <Box>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666",
                            fontWeight: 600,
                            fontSize: 12,
                            mb: 1,
                          }}
                        >
                          Key Themes
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            flexWrap: "wrap",
                          }}
                        >
                          {insightTopics && insightTopics.length > 0 && insightTopics.map((topic, index) => (
                            <Chip
                              key={index}
                              icon={
                                !exporting ? (
                                  <MagnifyingGlassIcon
                                    style={{
                                      height: 14,
                                      width: 14,
                                      color: "#666",
                                      marginLeft: 8,
                                    }}
                                  />
                                ) : null
                              }
                              label={
                                <Typography
                                  variant="caption"
                                  fontSize={14}
                                  color="#666"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                  fontWeight={600}
                                >
                                  {topic}
                                </Typography>
                              }
                              sx={{
                                backgroundColor: "#fff",
                                border: "2px solid #DDD",
                                fontSize: 12,
                                fontWeight: 600,
                                borderRadius: 2,
                                transition: "background-color 0.2s ease",
                                "&:hover": {
                                  backgroundColor: "rgba(71, 40, 196, 0.1)",
                                  "& svg": { color: "#666" },
                                },
                                "&:active": {
                                  backgroundColor: "rgba(71, 40, 196, 0.2)",
                                },
                              }}
                              onClick={() =>
                                !exporting && searchComments(topic)
                              }
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    </Fade>
               
                        
              </Box>

              {/* Notable Comments Section */}
              {!generatingInsights &&
                notableComments &&
                notableComments.length > 0 && (
                  <Box
                    sx={{
                      // border: hovered
                      //   ? "2px dashed #4728c4"
                      //   : "2px solid transparent",
                      borderRadius: 2,
                      transition: "all 0.2s ease",
                      minHeight:
                        notableComments.length === 0 ? "100px" : "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:
                        notableComments.length === 0 ? "center" : "flex-start",
                      alignItems:
                        notableComments.length === 0 ? "center" : "stretch",
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setHovered(true);
                    }}
                    // onDragLeave={() => setHovered(false)}
                    onDrop={(e) => {
                      e.preventDefault();
                      setHovered(false);
                      const commentData = e.dataTransfer.getData("comment");
                      if (commentData) {
                        handleDrop(commentData);
                      }
                    }}
                  >
                    {/* Header */}
                    {(!exporting || !hideNotableComments) &&
                      notableComments.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#666",
                              fontWeight: 600,
                              fontSize: 12,
                            }}
                          >
                            Notable Comments
                          </Typography>
                          {!exporting && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Tooltip title={hideNotableComments ? "Show in Export" : "Hide in Export"}>
                                <IconButton 
                                  size="small" 
                                  onClick={() => setHideNotableComments(!hideNotableComments)}
                                  sx={{ 
                                    opacity: 0.7,
                                    '&:hover': { opacity: 1 }
                                  }}
                                >
                                  {hideNotableComments ? (
                                    <EyeSlashIcon style={{ height: 16, color: '#666' }} />
                                  ) : (
                                    <EyeIcon style={{ height: 16, color: '#666' }} />
                                  )}
                                </IconButton>
                              </Tooltip>
                              {/* Only show other buttons if not hidden */}
                              {!hideNotableComments && (
                                <>
                                  <Tooltip title="Undo">
                                    <IconButton 
                                      size="small" 
                                      onClick={handleUndo}
                                      disabled={historyIndex <= 0}
                                      sx={{ 
                                        opacity: historyIndex <= 0 ? 0.3 : 0.7,
                                        '&:hover': { opacity: historyIndex <= 0 ? 0.3 : 1 }
                                      }}
                                    >
                                      <ArrowUturnLeftIcon style={{ height: 16, color: '#666' }} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Redo">
                                    <IconButton 
                                      size="small" 
                                      onClick={handleRedo}
                                      disabled={historyIndex >= notableCommentsHistory.length - 1}
                                      sx={{ 
                                        opacity: historyIndex >= notableCommentsHistory.length - 1 ? 0.3 : 0.7,
                                        '&:hover': { opacity: historyIndex >= notableCommentsHistory.length - 1 ? 0.3 : 1 }
                                      }}
                                    >
                                      <ArrowUturnRightIcon style={{ height: 16, color: '#666' }} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Copy Comments">
                                    <IconButton 
                                      size="small" 
                                      onClick={handleCopyNotableComments}
                                      sx={{ 
                                        opacity: 0.7,
                                        '&:hover': { opacity: 1 }
                                      }}
                                    >
                                      <DocumentDuplicateIcon style={{ height: 16, color: '#666' }} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Screenshot Comments">
                                    <IconButton 
                                      size="small" 
                                      onClick={handleScreenshotNotableComments}
                                      sx={{ 
                                        opacity: 0.7,
                                        '&:hover': { opacity: 1 }
                                      }}
                                    >
                                      <ArrowDownOnSquareIcon style={{ height: 16, color: '#666' }} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Box>
                          )}
                        </Box>
                      )}

                    {/* Empty State Message - only show when hovering */}
                    {notableComments.length === 0 &&
                      !hideNotableComments &&
                      hovered && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666",
                            fontSize: 12,
                            textAlign: "center",
                            fontStyle: "italic",
                            p: 2,
                          }}
                        >
                          Drag comments here to highlight key discussions
                        </Typography>
                      )}

                    {/* Comments List */}
                    {!hideNotableComments && notableComments.length > 0 && (
                      <Box
                        id="notable-comments-section"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          pl: 1,
                          pr: 1,
                        }}
                      >
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="notable-comments">
                            {(provided) => (
                              <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                {notableComments.map((comment, index) => (
                                  <Draggable
                                    key={`comment-${index}`}
                                    draggableId={`comment-${index}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                          transition: snapshot.isDragging
                                            ? "none"
                                            : "all 0.2s ease", // Only animate when not dragging
                                          transform: `${
                                            provided.draggableProps.style
                                              .transform
                                          } ${
                                            snapshot.isDragging
                                              ? "scale(1.02)"
                                              : "scale(1)"
                                          }`,
                                          position: "relative",
                                          userSelect: "none", // Prevent text selection during drag
                                          "&:active": {
                                            cursor: "grabbing",
                                          },
                                        }}
                                        onMouseEnter={() =>
                                          setHoveredCommentId(comment.id)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredCommentId(null)
                                        }
                                      >
                                        <Box
                                          id={`notable-comment-${comment.id}`}
                                          sx={{
                                            position: "relative",
                                            backgroundColor: exporting
                                              ? "#ffffff"
                                              : "transparent",
                                            borderRadius: exporting
                                              ? "12px"
                                              : 0,
                                            padding: exporting ? 2 : 0,
                                          }}
                                        >
                                          <CommentComponent
                                            comment={comment}
                                            quickView={false}
                                            isMobile={isMobile}
                                            handleRemoveComment={(e) =>
                                              handleRemoveComment(index, e)
                                            }
                                            isDraggable={true}
                                            small={true}
                                            screenShot={true}
                                          />
                                          {!exporting &&
                                            hoveredCommentId === comment.id && (
                                              <>
                                                {/* Actions on the right */}
                                                <Box
                                                  sx={{
                                                    position: "absolute",
                                                    right: 8,
                                                    top: 10,
                                                    display: "flex",
                                                    gap: 0.5,
                                                    background: "#f7f7f7",
                                                    boxShadow:
                                                      "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                                    padding: "5px 10px",
                                                    borderRadius: 30,
                                                  }}
                                                >
                                                  {" "}
                                                  <Tooltip title="Remove Comment">
                                                    <IconButton
                                                      size="small"
                                                      onClick={(e) =>
                                                        handleRemoveComment(
                                                          index,
                                                          e
                                                        )
                                                      }
                                                      sx={{
                                                        "&:hover": {
                                                          opacity: 1,
                                                        },
                                                      }}
                                                    >
                                                      <DeleteOutline
                                                        sx={{
                                                          fontSize: 16,
                                                          color: "#5C6078",
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Copy Comment">
                                                    <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                        handleCopyComment(
                                                          comment
                                                        )
                                                      }
                                                      sx={{
                                                        "&:hover": {
                                                          opacity: 1,
                                                        },
                                                      }}
                                                    >
                                                      <DocumentDuplicateIcon
                                                        style={{
                                                          height: 20,
                                                          width: 20,
                                                          color: "#5C6078",
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Screenshot Comment">
                                                    <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                        handleScreenshotComment(
                                                          comment.id
                                                        )
                                                      }
                                                      sx={{
                                                        "&:hover": {
                                                          opacity: 1,
                                                        },
                                                      }}
                                                    >
                                                      <ArrowDownOnSquareIcon
                                                        style={{
                                                          height: 20,
                                                          width: 20,
                                                          color: "#5C6078",
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Go to Post">
                                                    <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                        goToPost(comment.postId)
                                                      }
                                                      sx={{
                                                        "&:hover": {
                                                          opacity: 1,
                                                        },
                                                      }}
                                                    >
                                                      <ArrowTopRightOnSquareIcon
                                                        style={{
                                                          height: 20,
                                                          width: 20,
                                                          color: "#5C6078",
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Box>
                                              </>
                                            )}
                                        </Box>
                                      </Box>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Box>
                    )}
                  </Box>
                )}
            </>
          )}
        </Box>
      </Collapse>
    </>
  );
});

export default VibeCheckSection;

const StatElement = ({
  totalComments,
  statComments,
  title,
  description,
  color = "#6C5DD3",
}) => {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {/* Circular Progress */}
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={(statComments / totalComments) * 100}
          size={80}
          thickness={4}
          sx={{
            color: color,
            // backgroundColor: `${color}26`, // Lighter version of the color with transparency
            borderRadius: "50%",
            "& .MuiCircularProgress-circle": {
              strokeLinecap: "round", // Rounded edges for the circular progress bar
            },
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6" component="div" color={color}>
            {`${((statComments / totalComments) * 100).toFixed(1)}%`}
          </Typography>
        </Box>
      </Box>

      {/* Text Content */}
      <Box>
        <Typography variant="body1" fontWeight="bold" sx={{ color: "#000" }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "#888" }} fontWeight={700}>
          {`${statComments}/${totalComments} comments`}
        </Typography>
        <Typography variant="caption" sx={{ color: "#666" }}>
          {/* <InfoOutlined sx={{width:12}}/>  */}
          {/* <InformationCircleIcon  style={{height:12, width:12, paddingBottom:1}}/> */}

          {description}
        </Typography>
      </Box>
    </Box>
  );
};

function DataBar(props) {
  const {
    label,
    score,
    color,
    description,
    label1,
    label2,
    label3,
    data1,
    data2,
    data3,
    exporting,
    isMobile,
    quickView,
    vibeColor,
  } = props;

  const [open, setOpen] = React.useState(false);

  const numMetrics = [data1, data2, data3].filter((data) => data).length;

  let icons = {
    SENTIMENT: [
      <SentimentVerySatisfiedOutlined sx={{ height: 16, width: 16 }} />,
      <Mood sx={{ fontSize: 14 }} />,
      <SentimentVeryDissatisfiedOutlined sx={{ height: 16, width: 16 }} />,
    ],
    RELEVANCE: [
      <TaskAltOutlined sx={{ height: 16, width: 16 }} />,
      <Stars sx={{ fontSize: 14 }} />,
      <HighlightOffOutlined sx={{ height: 16, width: 16 }} />,
    ],
    CONSENSUS: [
      <HandshakeOutlined sx={{ height: 16, width: 16 }} />,
      <HowToVoteOutlined sx={{ fontSize: 14 }} />,
      <GavelOutlined sx={{ height: 16, width: 16 }} />,
    ],
  };

  const explanation = (label, score) => {
    if (label == "SENTIMENT") {
      if (score >= 7) {
        return "Overwhelming positivity.";
      } else if (score >= 5) {
        return "Mostly positive.";
      } else if (score >= 3) {
        return "Mix of positive and negative.";
      } else {
        return "Mostly negative.";
      }
    } else if (label == "RELEVANCE") {
      if (score >= 7) {
        return "High relevance.";
      } else if (score >= 5) {
        return "Mostly relevant.";
      } else if (score >= 3) {
        return "Somewhat off-topic.";
      } else {
        return "Mostly off-topic.";
      }
    } else if (label == "CONSENSUS") {
      if (score >= 7) {
        return "Strong agreement.";
      } else if (score >= 5) {
        return "Mostly in agreement.";
      } else if (score >= 3) {
        return "Mixed opinions.";
      } else {
        return "Mostly disagreeing.";
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        cursor: "pointer",
        borderRadius: 2,
        width: quickView ? "70%" : "100%",
      }}
      onClick={!quickView && data1 ? () => setOpen(!open) : null}
    >
      {!quickView && !isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.5,
            color: "#888",
          }}
        >
          {icons[label.toUpperCase()][1]}
          <Typography fontSize={10} fontWeight={600} color="#888">
            {label}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        {quickView && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              color: "#888",
            }}
          >
            {icons[label.toUpperCase()][1]}
            <Typography
              variant="body2"
              fontWeight={700}
              fontSize={14}
              color={vibeColor(score)}
            >
              {score}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.3,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              borderRadius: 30,
              height: 10,
              width: data1,
              background: "#EEE",
              opacity: 0.6,
              border: "transparent",
              transition: "width 0.5s ease-in-out", // Add this line
              "& .MuiLinearProgress-barColorPrimary": { background: "#089616" },
            }}
          />
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              borderRadius: 30,
              height: 10,
              width: data2,
              background: "#EEE",
              opacity: 0.6,
              border: "transparent",
              transition: "width 0.5s ease-in-out", // Add this line
              "& .MuiLinearProgress-barColorPrimary": { background: "#ffb93d" },
            }}
          />
          <LinearProgress
            variant="determinate"
            value={100}
            sx={{
              borderRadius: 30,
              height: 10,
              width: data3,
              background: "#EEE",
              opacity: 0.6,
              border: "transparent",
              transition: "width 0.5s ease-in-out", // Add this line
              "& .MuiLinearProgress-barColorPrimary": { background: "#fb3e3e" },
            }}
          />
        </Box>

        {!quickView && (
          <Typography variant="body2" fontWeight={700} fontSize={14}>
            {score}
          </Typography>
        )}

        {!quickView &&
          data1 &&
          !exporting &&
          !isMobile &&
          (open ? (
            <ExpandLessOutlined // Conditionally render the icon based on hover state
              sx={{
                cursor: "pointer",
                width: 20,
                height: 16,
                color: "#4727c3",
                marginLeft: 1, // Add some space between the text and the icon
              }}
            />
          ) : (
            <ExpandMoreOutlined // Conditionally render the icon based on hover state
              sx={{
                cursor: "pointer",
                width: 20,
                height: 16,
                color: "#4727c3",
                marginLeft: 1, // Add some space between the text and the icon
              }}
            />
          ))}
      </Box>
      <Box
        sx={{
          display: open ? "flex" : "none",
          p: isMobile ? 0.5 : 1,
          pl: isMobile ? 0.5 : 2,
          pr: isMobile ? 0.5 : 2,
          borderRadius: 2,
          backgroundColor: "#f7f7f7",
        }}
      >
        <Grow in={open}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 0.5,
              color: "#888",
              width: "100%",
            }}
          >
            {data1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexGrow: 1,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    gap: 0.5,
                  }}
                >
                  {/* {icons[label][0]} */}

                  <Typography
                    variant="body1"
                    fontSize={isMobile ? 8 : 10}
                    fontWeight={700}
                    align="left"
                    textTransform={"uppercase"}
                  >
                    {label1}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    fontSize={isMobile ? 14 : 18}
                    color="#333"
                  >
                    {data1}
                  </Typography>
                </Box>
              </Box>
            )}
            {data2 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                  // border: "1px solid #ddd",
                  // p: 1,

                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                    gap: 0.5,
                  }}
                >
                  {/* {icons[label][1]} */}

                  <Typography
                    variant="body1"
                    fontSize={isMobile ? 8 : 10}
                    fontWeight={700}
                    align="left"
                    textTransform={"uppercase"}
                  >
                    {label2}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      fontSize={isMobile ? 14 : 18}
                      color="#333"
                      align="left"
                    >
                      {data2}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {data3 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexGrow: 1,
                  // border: "1px solid #ddd",
                  // p: 1,

                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                    gap: 0.5,
                  }}
                >
                  {/* {icons[label][2]} */}

                  <Typography
                    variant="body1"
                    fontSize={isMobile ? 8 : 10}
                    fontWeight={700}
                    align="left"
                    textTransform={"uppercase"}
                  >
                    {label3}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      fontSize={isMobile ? 14 : 18}
                      color="#333"
                      align="left"
                    >
                      {data3}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grow>
      </Box>
    </Box>
  );
}


{/* 
              {!generatingInsights &&
                notablePosts &&
                notablePosts.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                      mb: 1,
                    }}
                  >
                    {(!exporting || !hideNotablePosts) && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#666",
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Top Posts
                        </Typography>

                        {!exporting && (
                          <Tooltip
                            title={
                              hideNotablePosts
                                ? "Show in Export"
                                : "Hide in Export"
                            }
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                setHideNotablePosts(!hideNotablePosts)
                              }
                              sx={{
                                opacity: 0.7,
                                "&:hover": { opacity: 1 },
                              }}
                            >
                              {hideNotablePosts ? (
                                <EyeSlashIcon
                                  style={{ height: 16, color: "#666" }}
                                />
                              ) : (
                                <EyeIcon
                                  style={{ height: 16, color: "#666" }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    )}

                    {!hideNotablePosts &&
                      notablePosts.map((post, index) => (
                        <Box
                          key={index}
                          onClick={() => goToPost(post.id)}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            alignItems: "center",
                            p: 1,
                            borderRadius: 2,
                            cursor: "pointer",
                            transition: "all 0.2s ease",
                            "&:hover": {
                              backgroundColor: "rgba(71, 40, 196, 0.05)",
                              transform: "translateX(4px)",
                            },
                            "&:active": {
                              backgroundColor: "rgba(71, 40, 196, 0.1)",
                              transform: "translateX(2px)",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              flexGrow: 1,
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#333",
                                fontWeight: 600,
                                fontSize: 12,
                                transition: "color 0.2s ease",
                                "&:hover": {
                                  color: "#4728c4",
                                },
                              }}
                            >
                              {post.data.creatorName}
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 2,
                                ml: "auto",
                                alignItems: "center",
                              }}
                            >
                              {post.data.engagement.views > 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                  }}
                                >
                                  <HeartIcon
                                    style={{ height: 12, color: "#666" }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#666", fontWeight: 600 }}
                                  >
                                    {formatNumber(post.data.engagement.views)}
                                  </Typography>
                                </Box>
                              )}
                              {post.data.engagement.likes > 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                  }}
                                >
                                  <HeartIcon
                                    style={{ height: 12, color: "#666" }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#666", fontWeight: 600 }}
                                  >
                                    {formatNumber(post.data.engagement.likes)}
                                  </Typography>
                                </Box>
                              )}

                              {post.data.engagement.comments > 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                  }}
                                >
                                  <ChatBubbleLeftIcon
                                    style={{ height: 12, color: "#666" }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#666", fontWeight: 600 }}
                                  >
                                    {formatNumber(
                                      post.data.engagement.comments
                                    )}
                                  </Typography>
                                </Box>
                              )}

                              {post.data.engagement.shares > 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                  }}
                                >
                                  <ArrowPathRoundedSquareIcon
                                    style={{ height: 12, color: "#666" }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#666", fontWeight: 600 }}
                                  >
                                    {formatNumber(post.data.engagement.shares)}
                                  </Typography>
                                </Box>
                              )}
                            </Box>

                            <Chip
                              label={formatPlatform(post.data.platform)}
                              size="small"
                              sx={{
                                fontSize: 10,
                                fontWeight: 600,
                                borderRadius: 8,
                                backgroundColor: "#f7f7f7",
                                border: "1px solid #eee",
                              }}
                            />
                          </Box>

                          {!exporting && (
                            <ArrowTopRightOnSquareIcon
                              style={{
                                height: 14,
                                width: 14,
                                color: "#666",
                                opacity: 0.5,
                                transition: "all 0.2s ease",
                              }}
                            />
                          )}
                        </Box>
                      ))}
                  </Box>
                )} */}


                      {/* Add Key Stats heading */}
                      {/* <Box>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: "#666", 
                        fontWeight: 600, 
                        fontSize: 12,
                        mb: 1 
                      }}
                    >
                      Key Stats
                    </Typography>
                    <Box sx={{ 
                      display: "flex", 
                      flexDirection: "row", 
                      gap: 3,
                      mb: 2
                    }}>
                      <Box>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: "#333",
                            fontWeight: 700,
                            fontSize: 20
                          }}
                        >
                          {sampledPercentage}%
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: "#666",
                            fontSize: 12
                          }}
                        >
                          of {totalCommentCount.toLocaleString()} Comments Sampled
                        </Typography>
                      </Box> */}

                      {/* <Box>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: "#333",
                            fontWeight: 700,
                            fontSize: 20
                          }}
                        >
                          {searchedPercentage}%
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: "#666",
                            fontSize: 12
                          }}
                        >
                          {searched 
                            ? <span>Comments about "<strong>{search}</strong>"</span>
                            : `of ${sampledCommentCount.toLocaleString()} Comments Analyzed`
                          }
                        </Typography>
                      </Box> */}

                      {/* <Box>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: "#333",
                            fontWeight: 700,
                            fontSize: 20
                          }}
                        >
                          {searchedCommentCount.toLocaleString()}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: "#666",
                            fontSize: 12
                          }}
                        >
                          {searched ? "Total Matching Comments" : "Total Analyzed Comments"}
                        </Typography>
                      </Box> */}
                      {/* </Box>
                  </Box> */}
